import { del, get, post, put } from "../helpers/api_helper"

export const UserService = {

  getBuyerTeamLead: async () => {
    return get('/get-buyer-team-lead');
  },

  getUsersBuyerTeamLead: async () => {
    return get(`/get-users-buyer-team-lead`);
  },

  setFcmToken: async (data: any) => {
    return post(`/user/fcm-token`, data)
  },
}