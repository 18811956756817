import React, {useEffect} from "react";
import {Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row} from "reactstrap";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {BrokerService} from "../../../../services/broker.service";
import Select from 'react-select';
import NoSearchResult from "../../../../components/Common/NoSearchResult";
import {useDispatch, useSelector} from "react-redux";
import {clearCountries, getCountries} from "../../../../store/countries/actions";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Flatpickr from "react-flatpickr";
import {formatDate} from "../../../../helpers/formatDate";
import {UserService} from "../../../../services/user.service";
import {TrafficOrderService} from "../../../../services/traffic_order.service";
import {buy_methods, pay_methods} from "../../../../constants/traffic_order";

interface ICreateOrder {
  isOpen: boolean,
  toggle: () => void,
}

const CreateOrder = ({isOpen, toggle}: ICreateOrder) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { dataCountries, loading } = useSelector((state: any) => ({
    dataCountries: state.Countries.data || [],
    loading: state.Countries.loading
  }));

  const { data: brokerData, isLoading, isError } = useQuery({
    queryKey: ["keyBrokerList"], queryFn: BrokerService.getBrokerList, staleTime: 60000 * 10
  });

  const { data: buyerData, isLoading: isLoadingBuyer, isError: isErrorBuyer } = useQuery({
    queryKey: ["keyBuyerTeamList"], queryFn: UserService.getBuyerTeamLead
  });

  const {mutate: createTrafficOrder, data: resultData, isError: isErrorCreateTrafficOrder, isPending, isSuccess: isSuccessCreateTrafficOrder, error, status} = useMutation({
    mutationFn: TrafficOrderService.createTrafficOrder,
    mutationKey: ['keyCrateTrafficOrder']
  });

  useEffect(() => {
    dispatch(getCountries());
    return () => {
      dispatch(clearCountries());
    };
  }, [dispatch]);

  useEffect(() => {
    if(isSuccessCreateTrafficOrder) {
      toggle()
      queryClient.invalidateQueries({ queryKey: ['keyTrafficOrders'] })

    }
  }, [isSuccessCreateTrafficOrder]);

  // Yup schema
  const validationSchema = Yup.object({
    broker_id: Yup.number().nullable(),
    buy_method_id: Yup.number().required('Buy method is required'),
    resale: Yup.string().when('buy_method_id', (buy_method_id) => {
      return (buy_method_id[0] === 2)
        ? Yup.string().required('Resale is required')
        : Yup.string().notRequired();
    }),
    user_ids: Yup.array().when('buy_method_id', (buy_method_id) => {
      return (buy_method_id[0] === 1)
        ? Yup.array().min(1, 'At least one team must be selected')
        : Yup.array().notRequired();
    }),
    geo_ids: Yup.array().min(1, 'At least one country must be selected'),
    funnel_name: Yup.string().required('Funnel name is required'),
    pay_method_id: Yup.number().required('Buy method is required'),
    payout: Yup.number().required('Payout is required').positive('Payout must be positive'),
    cr: Yup.number().when('pay_method_id', (pay_method_id) => {
      return (pay_method_id[0] === 3)
        ? Yup.number().required('CR is required').positive('Payout must be positive')
        : Yup.number().notRequired();
    }),
    daily_cap: Yup.number().required('Daily Cap is required').positive('Payout must be positive'),
    from_date: Yup.string().nullable().required('Start date is required'),
    to_date: Yup.string().nullable().required('End date is required'),
    from_time: Yup.string()
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Start time must be in the format HH:MM and between 00:00 and 23:59')
      .nullable()
      .required('Start time is required'),

    to_time: Yup.string()
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'End time must be in the format HH:MM and between 00:00 and 23:59')
      .nullable()
      .required('End time is required')
  });


  return (
    <Offcanvas isOpen={isOpen} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>
        Add Traffic Order
      </OffcanvasHeader>

      {!isLoading ? (
        <OffcanvasBody>
          <Formik
            initialValues={{
              broker_id: null,
              buy_method_id: null,
              resale: '',
              user_ids: [],
              geo_ids: [],
              funnel_name: '',
              pay_method_id: null,
              payout: '',
              cr: '',
              from_date: null,
              to_date: null,
              daily_cap: '',
              from_time:  null,
              to_time: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
              createTrafficOrder(values)
            }}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, isValid, errors }) => {
              return (
                <Form>

                  <div className="mb-4">
                    <label>Select date</label>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd M,yyyy"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                      }}
                      onChange={(date: any) => {
                        const [from_date, to_date] = date;
                        setFieldValue('from_date', from_date ? formatDate(from_date) : "");
                        setFieldValue('to_date', to_date ? formatDate(to_date) : "");
                      }}
                    />
                    <ErrorMessage name="from_date" component="div" className="text-danger"/>
                    <ErrorMessage name="to_date" component="div" className="text-danger"/>
                  </div>


                  <div className="mb-4">
                    <label>Select broker</label>
                    <Select
                      name="broker_id"
                      options={brokerData.map((i: any) => ({
                        value: i.value, label: i.label
                      })) || []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select broker..."
                      isClearable={true}
                      onChange={(option: any) => setFieldValue('broker_id', option ? option.value : null)}
                    />
                    <ErrorMessage name="broker_id" component="div" className="text-danger"/>
                  </div>

                  <div className="mb-4">
                    <label>Select buy method</label>
                    <Select
                      name="buy_method_id"
                      options={buy_methods}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select buy method..."
                      isClearable={true}
                      onChange={(option: any) => {
                        setFieldValue('buy_method_id', option ? Number(option.value) : null)
                        if(option.value !== 1) {
                          setFieldValue('user_ids', [])
                        }
                      }}
                    />
                    <ErrorMessage name="buy_method_id" component="div" className="text-danger"/>
                  </div>

                  {values.buy_method_id === 2 && (
                    <div className="mb-4">
                      <label>Resale</label>
                      <Field name="resale" as={Input} placeholder="Resale..."/>
                      <ErrorMessage name="resale" component="div" className="text-danger"/>
                    </div>
                  )}

                  {values.buy_method_id === 1 && (
                    <div className="mb-4">
                      <label>Select teams</label>
                      <Select
                        name="user_ids"
                        isMulti
                        options={buyerData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select teams..."
                        isClearable={true}
                        isLoading={isLoadingBuyer}
                        onChange={(options: any) => setFieldValue('user_ids', options ? options.map((o: any) => o.value) : [])}
                      />
                      <ErrorMessage name="user_ids" component="div" className="text-danger"/>
                    </div>
                  )}

                  <div className="mb-4">
                    <label>Select order GEO</label>
                    <Select
                      name="geo_ids"
                      isMulti
                      options={dataCountries.map((i: any) => ({
                        label: i.title, value: i.id
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select order geo..."
                      isClearable={true}
                      isLoading={loading}
                      onChange={(options: any) => setFieldValue('geo_ids', options ? options.map((o: any) => o.value) : [])}
                    />
                    <ErrorMessage name="geo_ids" component="div" className="text-danger"/>
                  </div>

                  <div className="mb-4">
                    <label>Funnel name</label>
                    <Field name="funnel_name" as={Input} placeholder="Funnel name..."/>
                    <ErrorMessage name="funnel_name" component="div" className="text-danger"/>
                  </div>

                  <div className="mb-4">
                    <label>Select pay method</label>
                    <Select
                      name="pay_method_id"
                      options={pay_methods}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select pay method..."
                      isClearable={true}
                      onChange={(option: any) => {
                        setFieldValue('pay_method_id', option ? option.value : null)
                        if(option.value !== 3) {
                          setFieldValue('cr', null)
                        }
                      }}
                    />
                    <ErrorMessage name="pay_method_ids" component="div" className="text-danger"/>
                  </div>

                  <Row>
                    <div className={`mb-4 ${values.pay_method_id === 3 ? "col-8" : ""}`}>
                      <label>Payout</label>
                      <Field name="payout" as={Input} placeholder="Payout..." />
                      <ErrorMessage name="payout" component="div" className="text-danger" />
                    </div>
                    {values.pay_method_id === 3 && (
                      <div className="mb-4 col-4">
                        <label>CR %</label>
                        <Field name="cr" as={Input} placeholder="CR..." />
                        <ErrorMessage name="cr" component="div" className="text-danger" />
                      </div>
                    )}
                  </Row>

                  <div className="mb-4">
                    <label>Daily Cap</label>
                    <Field name="daily_cap" as={Input} placeholder="Daily Cap..."/>
                    <ErrorMessage name="daily_cap" component="div" className="text-danger"/>
                  </div>

                  <Row>
                    <label>Call center time</label>

                    <div className="mb-4 col-6">
                      <Label htmlFor="start-time-input" className="form-Label">Start Time</Label>
                      <Field
                        className="form-control"
                        type="time"
                        name="from_time"
                        id="start-time-input"
                        defaultValue="00:01"
                        placeholder="Start Time"
                      />
                      <ErrorMessage name="from_time" component="div" className="text-danger" />
                    </div>

                    <div className="mb-4 col-6">
                      <Label htmlFor="to-time-input" className="form-Label">End Time</Label>
                      <Field
                        className="form-control"
                        type="time"
                        name="to_time"
                        id="to-time-input"
                        defaultValue="23:59"
                        placeholder="End Time"
                      />
                      <ErrorMessage name="to_time" component="div" className="text-danger" />
                    </div>
                  </Row>

                  <button type="submit" className="btn btn-primary w-100" disabled={!isValid}>
                    Save
                  </button>

                </Form>
              );
            }}
          </Formik>
        </OffcanvasBody>
      ) : (
        <NoSearchResult text="Loading ..."/>
      )}
    </Offcanvas>
  );
};

export default CreateOrder;