import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css"
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store/index";
import {ToastContainer} from "react-toastify";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {BroadcastProvider} from "./providers/BroadcastProvider";

const getId : any = document.getElementById('root');
const root = ReactDOM.createRoot(getId);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={configureStore({})}>
      <BrowserRouter>
        <BroadcastProvider >
          <ToastContainer autoClose={2000} stacked/>
          <App />
        </BroadcastProvider>
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
);