import React, { useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import NoSearchResult from "../../../components/Common/NoSearchResult";
import CardOrder from "./components/CardOrder";
import CreateOrder from "./components/CreateOrder";
import { TrafficOrderService } from "../../../services/traffic_order.service";
import AssignOrder from "./components/AssignOrder";
import AssignIntegra from "./components/AssignIntegra";
import InfoOrder from "./components/InfoOrder";
import UpdateOrder from "./components/UpdateOerder";
import ChangeStatus from "./components/ChangeStatus";
import usePermissions from "../../../hooks/usePermissions";
import classnames from "classnames";
import {toast} from "react-toastify";

interface ModalState {
  isRight: boolean;
  isUpdate: boolean;
  isInfoOrder: boolean;
  isAssignOrder: boolean;
  isAssignIntegra: boolean;
  isChangeStatus: boolean;
  selectedOrderId: string | null;
}

const TrafficOrders: React.FC = () => {
  const {hasPermission} = usePermissions();
  const [activeTab1, setactiveTab1] = useState("1");

  if(!hasPermission('view-traffic-orders')){
    window.location.href = "/dashboard";
  }

  const [modalState, setModalState] = useState<ModalState>({
    isRight: false,
    isUpdate: false,
    isInfoOrder: false,
    isAssignOrder: false,
    isAssignIntegra: false,
    isChangeStatus: false,
    selectedOrderId: null,
  });

  const toggleModal = (key: keyof ModalState, id?: string | null) => {
    setModalState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
      selectedOrderId: id || null,
    }));
  };

  const {
    data: trafficOrders,
    isSuccess,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["keyTrafficOrders"],
    queryFn: TrafficOrderService.getTrafficOrders,
    retry: false,
  });

  const {
    data: trafficOrdersWaiting,
    isSuccess: isSuccessOrdersWaiting,
    isLoading: isLoadingOrdersWaiting,
    isError: isErrorOrdersWaiting,
  } = useQuery({
    queryKey: ["keyTrafficOrdersWaiting"],
    queryFn: TrafficOrderService.getTrafficOrdersWaiting,
    retry: false,
  });

  const modals = [
    { key: "isRight", Component: CreateOrder },
    { key: "isUpdate", Component: UpdateOrder },
    { key: "isInfoOrder", Component: InfoOrder },
    { key: "isAssignOrder", Component: AssignOrder },
    { key: "isAssignIntegra", Component: AssignIntegra },
    { key: "isChangeStatus", Component: ChangeStatus },
  ];

  const toggle1 = (tab: any) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  console.log("trafficOrdersWaiting", trafficOrdersWaiting)

  const closeAllToast = () => {
    toast.dismiss();
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Dashboard" breadcrumbItem="Traffic Orders" />
        <Row className="align-items-center">
          <Col md={12} className="d-sm-flex justify-content-between">
            <div className="align-items-center justify-content-start gap-2 mb-3">
              <Button onClick={() => closeAllToast()} className="btn btn-light" disabled={!hasPermission('add-traffic-orders')}>
                Read all notify
              </Button>
            </div>
            <div className="align-items-center justify-content-end gap-2 mb-3">
              <Button onClick={() => toggleModal("isRight")} className="btn btn-light" disabled={!hasPermission('add-traffic-orders')}>
                <i className="bx bx-plus me-1"></i> Add Order
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <Nav pills className="navtab-bg nav-justified mb-4">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab1 === "1",
                  })}
                  onClick={() => {
                    toggle1("1");
                  }}
                >
                  Active
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab1 === "2",
                  })}
                  onClick={() => {
                    toggle1("2");
                  }}
                >
                  Waiting
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab1} className="p-3 text-muted">
              <TabPane tabId="1">
                <Card>
                  <CardBody className="border-bottom">
                    {isLoading ? (
                      <NoSearchResult text="Loading ..." />
                    ) : isError ? (
                      <NoSearchResult text="Error loading data" />
                    ) : (
                      isSuccess && (
                        <Row>
                          {!trafficOrders?.length && (
                            <NoSearchResult text="No traffic orders available" />
                          )}
                          {trafficOrders?.map((order: any, idx: number) => (
                            <CardOrder
                              data={order}
                              key={`card_${idx}`}
                              toggleAssignOrder={(id) => toggleModal("isAssignOrder", id)}
                              toggleAssignIntegra={(id) => toggleModal("isAssignIntegra", id)}
                              toggleInfoOrder={(id) => toggleModal("isInfoOrder", id)}
                              toggleUpdate={(id) => toggleModal("isUpdate", id)}
                              toggleChangeStatus={(id) => toggleModal("isChangeStatus", id)}
                            />
                          ))}
                        </Row>
                      )
                    )}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <CardBody className="border-bottom">
                  {isLoading ? (
                    <NoSearchResult text="Loading ..." />
                  ) : isError ? (
                    <NoSearchResult text="Error loading data" />
                  ) : (
                    isSuccess && (
                      <Row>
                        123
                      </Row>
                    ))}
                </CardBody>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        {modals.map(({ key, Component }) =>
          modalState[key as keyof ModalState] ? (
            <Component
              key={key}
              isOpen={!!modalState[key as keyof ModalState]}
              toggle={() => toggleModal(key as keyof ModalState)}
              id={modalState.selectedOrderId || ""}
            />
          ) : null
        )}
      </Container>
    </div>
  );
};

export default TrafficOrders;