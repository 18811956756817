import { del, get, post, put } from "../helpers/api_helper"

export const TrafficOrderService = {

  getTrafficOrders: async () => {
    return get('/traffic-orders')
  },
  getTrafficOrdersWaiting: async () => {
    return get('/traffic-orders/waiting')
  },

  getTrafficOrder: async (id: number) => {
    return get(`/traffic-orders/${id}`)
  },

  createTrafficOrder: async (data: any) => {
    return post('/traffic-orders', data)
  },

  getResponsibilityTrafficOrder: async (id: number) => {
    return get(`/traffic-orders/${id}/responsibility`)
  },

  assignTrafficOrder: async (data: any, id: number) => {
    return post(`/traffic-orders/${id}/responsibility`, data)
  },

  getBrokerTrafficOrder: async (id: number) => {
    return get(`/traffic-orders/${id}/broker`)
  },

  updateBrokerTrafficOrder: async (data: any, id: number) => {
    return post(`/traffic-orders/${id}/broker`, data)
  },

  updateStatusTrafficOrder: async (data: any, id: number) => {
    return post(`/traffic-orders/${id}/status`, data)
  },

  updateTrafficOrder: async (id: number, data: any) => {
    return put(`/traffic-orders/${id}`, data)
  },

}