import React, { useMemo, useEffect } from "react";
import { Form, ModalBody, ModalHeader, Modal, Row, Col } from "reactstrap";
import { useFormik } from "formik";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import * as Yup from "yup";
import Select from "react-select";
import { UserService } from "../../../../services/user.service";
import { TrafficOrderService } from "../../../../services/traffic_order.service";

interface IAssignOrder {
  isOpen: boolean;
  toggle: () => void;
  id: string;
}

interface AssignOrderData {
  buyer_ids: number[];
}

const validationSchema = Yup.object({
  buyer_ids: Yup.array().min(1, "At least one buyer must be selected"),
});

const AssignOrder: React.FC<IAssignOrder> = ({ isOpen, toggle, id }) => {
  const queryClient = useQueryClient();

  const { data: usersBuyerTeamLead, isLoading: isLoadingUsers, isSuccess:  isSuccessUsersBuyerTeamLead} = useQuery({
    queryKey: ["keyUsersBuyerTeamLead"],
    queryFn: UserService.getUsersBuyerTeamLead,
    retry: false,
  });

  const { data: responsibilityTrafficOrder } = useQuery({
    queryKey: ["keyResponsibilityTrafficOrder", id],
    queryFn: () => TrafficOrderService.getResponsibilityTrafficOrder(+id),
  });

  const { mutate: assignTrafficOrder, isPending, isSuccess: isSuccessAssignTrafficOrder } = useMutation({
    mutationFn: (data: AssignOrderData) => TrafficOrderService.assignTrafficOrder(data, +id),
    mutationKey: ["keyAssignTrafficOrder", id],
  });

  const initialValues = useMemo(
    () => ({
      buyer_ids: responsibilityTrafficOrder ? responsibilityTrafficOrder.map((i: any) => i.value) : [],
    }),
    [responsibilityTrafficOrder]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      assignTrafficOrder(values);
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
  });

  const { handleChange, submitForm, setFieldValue, values, touched, errors } = formik;

  useEffect(() => {
    if (responsibilityTrafficOrder) {
      setFieldValue(
        "buyer_ids",
        responsibilityTrafficOrder.map((option: any) => option.value)
      );
    }
  }, [responsibilityTrafficOrder, setFieldValue]);

  useEffect(() => {
    if(isSuccessAssignTrafficOrder) {
      toggle();
      queryClient.invalidateQueries({ queryKey: ['keyTrafficOrders'] });
    }
  }, [isSuccessAssignTrafficOrder]);

  useEffect(() => {
    if(!isSuccessUsersBuyerTeamLead){
      toggle();
    }
  }, [isSuccessUsersBuyerTeamLead]);

  const handleClickSubmit = async () => {
    const currentErrors = await formik.validateForm();
    if (Object.keys(currentErrors).length === 0) {
      submitForm();
    }
  };

  return (
    <Modal isOpen={isOpen && isSuccessUsersBuyerTeamLead} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Assign Order {id}
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation">
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <label>Select buyers responsibility</label>
                <Select
                  name="buyer_ids"
                  options={usersBuyerTeamLead}
                  isLoading={isLoadingUsers}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select buyers responsibility"
                  isClearable
                  isMulti
                  value={usersBuyerTeamLead?.filter((user: any) =>
                    values.buyer_ids.includes(user.value)
                  )}
                  onChange={(options: any) =>
                    setFieldValue(
                      "buyer_ids",
                      options ? options.map((option: any) => option.value) : []
                    )
                  }
                />
                {/*{touched.buyer_ids && errors.buyer_ids && (
                  <div className="text-danger">{errors.buyer_ids}</div>
                )}*/}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <button
                type="button"
                className="btn btn-success save-user"
                onClick={handleClickSubmit}
                disabled={!formik.isValid || isPending}
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AssignOrder;