import {RolesTypes, RolesState} from "./actionTypes"

export const INIT_STATE : RolesState = {
    data: [],
    item: {},
    loading: false,
    error : "",
    isOpenModal: false,
    loaded: false
  }

 const roles = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case RolesTypes.GET_ROLES:
        return {
          ...state,
          loading: true
        }

        case RolesTypes.GET_ROLES_SUCCESS:
          return {
            ...state,
            data: action.payload.data,
            loaded: true,
            loading: false
          }

        case RolesTypes.GET_ROLE_SUCCESS:
          return {
            ...state,
            item: action.payload,
          }

          case RolesTypes.CLEAR_ROLE:
            return {
              ...state,
              item: {},
              error: {},
            }
        case RolesTypes.ADD_ROLES_SUCCESS:
              return {
                ...state,
                data: [...state.data, action.payload.data],
              }
        case RolesTypes.UPD_ROLES_SUCCESS:
                const items = state.data.map((item : any) => {
                  return (item.id === action.payload.id) ? action.payload : item
                })
                return {
                  ...state,
                  data: items,
                  error: {},
                  loading: false,
                  loaded: true
                }  
        case RolesTypes.DEL_ROLES_SUCCESS:
                  return {
                    ...state,
                    data: state.data.filter((item : any) => item.id.toString() !== action.payload.toString() ),
                    error: {},
                    loading: false,
                    loaded: true
                  }
        case RolesTypes.ROLES_FAIL:
                    return {
                      ...state,
                      error: action.payload,
                      loaded: false,
                      loading: false
                    }      
        case RolesTypes.ROLES_MODAL:
                return {
                  ...state,
                  isOpenModal: action.status
                }
        default:
        return state;
    }
  }

  export default roles;