import PropTypes from "prop-types";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Progress,
} from "reactstrap";
import {useQueryClient} from "@tanstack/react-query";
import {ORDER_STATUSES} from "../constants/order";
import usePermissions from "../../../../hooks/usePermissions";

const CardOrder = (props: any) => {
  const {hasPermission} = usePermissions();

  const {
    data,
    toggleAssignOrder,
    toggleAssignIntegra,
    toggleInfoOrder,
    toggleUpdate,
    toggleChangeStatus
  } = props;

  const status = ORDER_STATUSES.find((status) => status.value === data?.status);

  return (
    <React.Fragment>
      <Col xl="3" md="3" sm="4">
        <Card className="text-center">
          <CardBody>

            <h5 className="font-size-16 mb-1">
              <Link to={`/country-boxes/edit/${data.id}`} className="text-body">
                {data?.broker?.name}
              </Link>
            </h5>

            <>
              <div style={{position: 'relative', width: '100%'}}>
                <Progress striped max={data?.daily_cap || 0} value={data?.filtered_capas[0]?.capa_day || 0}
                          className="my-1" color={"success"} style={{height: '20px', backgroundColor: '#B9BBB6'}}>
                <span style={{
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  color: 'white',
                  lineHeight: '20px'
                }}>
                  {data?.filtered_capas[0]?.capa_day || 0}/{data?.daily_cap || 0}
                </span>

                </Progress>
              </div>
            </>

            <div className={"font-size-12"}>
              {data.countries.map((i: any) => {
                return (<span key={i.id}>{i.iso} </span>)
              })}
            </div>

            <div style={{cursor: 'pointer'}}>
              <span className={`badge ${status?.colorClass || 'bg-secondary'}`}
                    onClick={() => toggleChangeStatus(data.id)}>
                <i className={`bx ${status?.iconClass || 'bx-info-circle'} font-size-16 align-middle me-2`}/>
                {status?.label || 'Unknown'}
              </span>
            </div>

            <div>
              <span className={`badge ${data.broker_id ? 'bg-success' : 'bg-danger'}`}>
                <i className={`bx ${data.broker_id ? 'bx-check-double' : 'bx-block'} font-size-16 align-middle me-2`}/>
                {data.broker_id ? 'Integrated' : 'Not Integrated'}
              </span>
            </div>

            <div className={"mt-2"}>
              {data.responsible_users && data?.responsible_users.map((i: any, idx: number) => {
                return <span className="rounded-pill bg-dark-subtle text-dark badge bg-secondary "
                             key={idx}>{i.name}</span>
              })}
            </div>

            <div>
              Pay method: {data?.pay_method_label}
            </div>

            <div>
              Funnel name: {data?.funnel_name}
            </div>

            <div className="font-size-10" style={{fontWeight: 'bold'}}>
              Date: {data?.from_date} {data?.from_date !== data?.to_date ? '' : `data?.to_date`}
            </div>
          </CardBody>

          <div className="btn-group" role="group">
            {hasPermission('assign-traffic-order') && <button type="button" className="btn btn-outline-light text-truncate" title="Assign"
                     onClick={() => toggleAssignOrder(data.id)}>
              <i className="mdi mdi-plus-circle font-size-14"/>
            </button>}
            {hasPermission('view-info-traffic-order') && <button type="button" onClick={() => toggleInfoOrder(data.id)}
                     className="btn btn-outline-light text-truncate" title="Info">
              <i className="mdi mdi-information font-size-14"/>
            </button>}
            {hasPermission('edit-traffic-order') && <button type="button" onClick={() => toggleUpdate(data.id)} className="btn btn-outline-light text-truncate"
                     title="Edit">
              <i className="mdi mdi-pencil font-size-14"/>
            </button>}
            {hasPermission('integrated-traffic-order') && <button type="button" onClick={() => toggleAssignIntegra(data.id)}
                     className="btn btn-outline-light text-truncate" title="Integrated">
              <i className="mdi mdi-book-cog font-size-14"/>
            </button>}
          </div>

        </Card>
      </Col>
    </React.Fragment>
  );
};

CardOrder.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  onDelete: PropTypes.func,
  toggleAssignOrder: PropTypes.func,
  toggleAssignIntegra: PropTypes.func,
  toggleInfoOrder: PropTypes.func,
  toggleUpdate: PropTypes.func,
  toggleChangeStatus: PropTypes.func
};

export default CardOrder;
