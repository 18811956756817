import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBskW7JTJp5ryMIlOtWACE2iMqgZ_wvaWU",
  authDomain: "board-projektsolutions.firebaseapp.com",
  projectId: "board-projektsolutions",
  storageBucket: "board-projektsolutions.appspot.com",
  messagingSenderId: "1029217389065",
  appId: "1:1029217389065:web:03af903865428fb21d46d8"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: "BG_qw3ft_LUMZPa9PYi3z2B9PziNkvfEio-288NUKC1AGwW58wx0Li9_Amrj9vwGjaBeGatJktfRMaWaxluaZwQ",
    });
    if (currentToken) {
      return currentToken;
    } else {
      console.log("No registration token available. Request permission to generate one.");
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });